<template>  
  <v-container fluid>
    <TheTitle :text="title" :icon="pageIcon">
      <v-row justify="center">
        <v-col md="8" sm="10" cols="10">
          <v-text-field
            placeholder="Procurar"
            dense
            solo
            background-color="#FFFFFF"
            hide-details
            v-model="search"
          ></v-text-field>
        </v-col>
        <v-col md="4" sm="4" cols="4">
          <v-btn block color="amber darken-4" dark @click="dialogSave()">
            <v-icon small class="mr-2">mdi-plus</v-icon> Adicionar</v-btn
          >
        </v-col>
      </v-row>
    </TheTitle>

    <v-card class="pa-5 full-height">
      <v-data-table
        :headers="header"
        :items="objects"
        dense
        locale="pt-BR"
        :search="search"        
      >
        <template v-slot:[`item.action`]="{ item }">
          <TheToolTip label="Editar">
            <v-btn icon small tile>
              <v-icon @click="dialogUpdate(item)">mdi-pen</v-icon>
            </v-btn>
          </TheToolTip>
          <TheToolTip label="Remover">
            <v-btn icon small tile>
              <v-icon @click="remove(item.id)">mdi-minus</v-icon>
            </v-btn>
          </TheToolTip>
        </template>
      </v-data-table>
    </v-card>

    <TheDialog v-model="dialog" :title="title" :icon="pageIcon">
      <template>
        <v-form v-model="valid" ref="form1">
          <b>Nome do Cargo<sup>*</sup></b>
          <v-text-field
            :rules="requiredRule"
            placeholder="Nome"
            v-model="object.name"
            class="my-2"
            dense
            hide-details
            outlined
            color="primary"
          ></v-text-field>
          <b>Superior</b>
          <v-select
            placeholder="Selecione"
            v-model="object.idSuperior"
            :items="objects"
            item-text="name"
            item-value="id"
            class="my-2"
            dense
            hide-details
            outlined
            color="primary"
          ></v-select>
          <b>Setor<sup>*</sup></b>
          <v-select
            placeholder="Selecione"
            v-model="object.idSector"
            :items="sector"
            item-text="name"
            item-value="id"
            class="my-2"
            dense
            hide-details
            outlined
            color="primary"
            :rules="[requiredRule]"
          ></v-select>
        </v-form>
      </template>
      <template v-slot:actions="">
        <v-btn
          small
          depressed
          color="primary"
          class="mt-2"
          dark
          @click="resolveForm()"
        >
          <v-icon small class="mr-2">mdi-plus</v-icon>
          <span v-if="!edit">Adicionar</span>
          <span v-if="edit">Atualizar </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="ml-5 caption"><sup>*</sup>Campos obrigatórios</span>
      </template>
    </TheDialog>
  </v-container>
</template>

<script>
import axios from "axios"
import { baseApiUrl, showError } from "@/global"
import TheTitle from "@/components/TheTitle"
import TheDialog from "@/components/TheDialog"
import TheToolTip from "@/components/TheToolTip"

export default {
  name: "Dados",
  components: {
    TheTitle,
    TheDialog,
    TheToolTip,
  },
  data: () => ({
    testeDados: {},
    baseRoute: "position",
    title: "Gerência dos Cargos",
    pageIcon: "mdi-account-network",

    object: {},
    objects: [],
    sector: [],

    dialog: false,
    search: null,
    valid: false,
    error: false,
    edit: false,

    header: [
      { text: "Setor", value: "sectorName", width: "30%" },
      { text: "Nome", value: "name" },
      { text: "Superior", value: "superiorName" },
      { text: "Ações", value: "action", width: "10%" },
    ],
  }),
  methods: {

    loadData() {
      this.$store.dispatch("setLoadingInfo", true)
      const url = `${baseApiUrl}/${this.baseRoute}`
      axios
        .get(url)
        .then((res) => {
          this.$store.dispatch("setLoadingInfo", false)
          this.objects = res.data
        })
        .catch(showError)
    },

    loadSector() {
      const url = `${baseApiUrl}/sector`
      axios
        .get(url)
        .then((res) => {
          this.sector = res.data
        })
        .catch(showError)
    },

    dialogSave() {
      this.object = {}
      this.edit = false
      this.dialog = true
    },
    dialogUpdate(item) {
      this.object = item
      this.edit = true
      this.dialog = true
    },
    resolveForm() {
      this.$refs.form1.validate()
      if (!this.valid) {
        this.$store.dispatch("setErrorInfo", {
          open: true,
          text:
            "Os campos não foram preenchidos corretamente, você pode verificar?",
          button: "Ok!",
        })
        return
      }
      if (this.edit) {
        this.update()
      } else {
        this.save()
      }
    },
    save() {
      this.error = false
      const url = `${baseApiUrl}/${this.baseRoute}`
      const objects = this.object
      axios
        .post(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    update() {
      this.error = false
      const id = this.object.id
      const url = `${baseApiUrl}/${this.baseRoute}/${id}`
      const objects = this.object
      delete objects.id
      delete objects.sectorName
      delete objects.superiorName
      delete objects.createdAt
      delete objects.updatedAt
      delete objects.deletedAt
      axios
        .put(url, objects)
        .then(() => this.loadData())
        .catch(showError)
        .finally(() => {
          this.dialog = false
        })
    },
    remove(id) {
      if (confirm("Remove?")) {
        const url = `${baseApiUrl}/${this.baseRoute}/${id}`
        axios
          .delete(url)
          .then(() => this.loadData())
          .catch(showError)
      }
    },
  },
  mounted() {
    this.loadData()
    this.loadSector()
  },
}
</script>

<style>
  .full-height {
    height: 80vh;
  }
</style>
